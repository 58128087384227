<template>
  <div class="settings">
    <div style='position: fixed; left: 0; width: 33%;  background-color: #f4f5f7; z-index: -2;'></div>
    <div style='position: fixed; top: 0; right: 0; width: 33%; height: 100%; background-color: #f4f5f7; z-index: -1;'></div>
    <div class="pageContainer" style="display: flex; background-color: #f4f5f7!important;">
      <feed-nav activeLink="feed_notifications"></feed-nav>

      <div class="content" :style="{ 'min-height': windowHeight - 110 + 'px' }">

        <div style="display: flex; justify-content: space-between">
          <p class="n-page-header">Bildirimler</p>
          <div class="read-all">
            <p @click="markClickedAll" v-if="unclickedCount">Hepsini Okundu Yap</p>
          </div>
        </div>

        <loader v-if="!pageLoaded"></loader>
        <div v-else>
          <div style=" margin-top: 40px; margin-left: 57px;">
            <div v-if="!notifications.length">Henüz bir bildirimin yok.</div>

            <div @click="handleNotifClick(notification)" v-if="notifications.length" v-for="notification in notifications" class="notification-row" style="position: relative">
              <div style="margin-right: 15px; width: 14px;">
                <div v-if="!notification.read_at" class="red-dot"></div>
              </div>
              <div v-if="notification.image_type === 'image'">
                  <img class="img" :src="notification.image_url">
              </div>

                <div  v-else class="icon" :style="'background-color:'+notification.icon_background_color">
                  <img :src="notification.icon" onload="SVGInject(this)" class="icon-svg">
                </div>


              <div style="width: 670px">
                <p class="title"> {{ notification.title }} </p>
                <p class="text"> {{ notification.text }} <span v-if="notification.bold_text">{{notification.bold_text}}</span></p>
              </div>

              <div class="since" style="position: absolute; right: 15px;">
                {{notification.since}}
              </div>

            </div>
            <loader v-if="seemoredivLoader"></loader>

            <div v-if="seemorediv" @click="retrieveNotifications(limit, offset)" style="cursor: pointer; margin-top:20px;font-size: 14px; font-weight: 600;text-align: center; color: #2d3640;">
              Daha fazla gör +
            </div>

          </div>
        </div>

        <div class="hiddendiv"></div><div class="hiddendiv"></div><div class="hiddendiv"></div><div class="hiddendiv"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import FeedNav from  "../../../components/shared/globalSideNav";
  import Loader from "../../../components/toolbox/loader";

  export default {
    name: "src-pages-body-feed-notifications-v1",
    components: {
      Loader,
      FeedNav},
    data () {
      return {
        unclickedCount:0,
        seemorediv: false,
        pageLoaded: false,
        seemoredivLoader: false,
        showReadAllLink:true,
        notifications: [],
        offset: 0,
        limit: 50
      }
    },

    methods: {
      handleNotifClick(notification){
        this.api.user.readNotification(notification.id);
        this.$router.push(notification.target_url);
      },

      markClickedAll(){
        if(!this.showReadAllLink) return false;
        this.showReadAllLink = false;
        this.api.user.readNotification('all').then(({data}) => {
          this.showReadAllLink = true;
          this.offset = 0;
          this.retrieveNotifications(this.limit, this.offset, true)
        });
      },

      retrieveNotifications(limit, offset, reset=false) {
        this.seemoredivLoader = true;
        this.api.user.retrieveNotifications(limit, offset)
        .then(({data}) => {
        	let result = data;

        	if (result.success) {
            this.pageLoaded = true;
            this.seemoredivLoader = false;
            this.offset += this.limit;
            this.seemorediv = result.data.seemorediv;
            if(reset){
              this.notifications = result.data.notifications;
            } else {
              this.notifications = this.notifications.concat(result.data.notifications);
            }
            this.unclickedCount = result.data.unread_count;

          } else {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          }
        })
        .catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        })
      }
    },

    created() {
      this.retrieveNotifications(this.limit, this.offset);
      this.EventBus.$on('reloadNotificationPage', payload => {
        if (payload === 'reload') {
          this.pageLoaded = false;
          this.retrieveNotifications(this.limit, this.offset, true);
          window.scrollTo(0, 0);
        }
      });
    },
    beforeDestroy() {
      this.EventBus.$off('reloadNotificationPage');
    }
  }

</script>

<style scoped lang="scss">

  .read-all{
    font-size: 16px; font-weight: 500; color: #00a575; padding-top: 48px;

    p{
      cursor: pointer;
      &:hover{
        text-decoration: underline;
      }
    }

  }
  .n-page-header{
    color: #2d3640;
    font-size: 34px;
    margin-left: 57px;
    margin-top: 30px;
  }

  .notification-row{
    cursor: pointer;
    width: 843px;
    height: 94px;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    padding-left: 15px;



    .icon-svg /deep/ {
      width: 23px;
      height: 23px;
      path {
        fill: #fff;
      }
    }

    .img{
      border-radius: 50%;
      height: 51px; width: 51px; border:1px solid #eff3f8;
      margin-right: 20px;
    }
    .icon{
      height: 51px; width: 51px; border:1px solid #eff3f8;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
    }

    .title{
      font-size: 16px;
      font-weight: 300;
      color: #8b95a1;
    }

    .text{
      font-size: 16px;
      font-weight: 300;
      line-height: 1.38;
      color: #2d3640;
      margin-top: 2px;
      span{
        font-weight: 600;
        color: #00a575;
      }
    }

    .since{
      font-size: 12px;
      text-align: right;
      color: #8b95a1;
      align-self: flex-start;
      margin-top: 20px;
      margin-left: 15px;
      margin-right: 15px;
    }

    .red-dot{
      background: #fd4056; width: 14px; height: 14px; border-radius: 50%;
    }
  }


  .chatrow{

    height: 60px; background-color: #ffffff;  display: flex; align-items: center;
    border-bottom: solid 1px #eaedf2;
  }

  .chatrow.unseen{
    height: 60px; background-color: rgba(245, 245, 245,0.7);  display: flex; align-items: center;
    border-top: solid 1px #eaedf2;
  }


  .chatrow.unseen > .title{
    font-weight: 600;
    color: #2d3640;
    font-weight: 600;
  }



  .title{
    font-size: 13px;
    font-weight: 400;
    line-height: 1.29;
    color: #8b95a1;
  }


  .messagenavlink{
    cursor: pointer;
  }
  .messagenavlink:hover{
    color: #2d3640;
  }


  .usernamefollow{
    font-size: 14px;
    line-height: 1.29;

  }

  .usernamefollow:hover{
    text-decoration: underline;

  }

</style>
